

export const fields = [
    {"label":"input-text", "type": "input-text","svg":"/icons/svg/input-text.svg", "fieldOptions":false},
    {"label":"input-number",  "type": "input-number","svg":"/icons/svg/input-numeric.svg", "fieldOptions":false},
    {"label":"password",  "type": "password","svg":"/icons/svg/lock.svg", "fieldOptions":false},
    {"label":"email",  "type": "email","svg":"/icons/svg/at.svg", "fieldOptions":false},
    {"label":"input-url", "type": "input-url", "svg":"/icons/svg/link.svg", "fieldOptions":false},
    {"label":"Textarea", "type": "textarea", "svg":"/icons/svg/align-left.svg", "fieldOptions":false},
    {"label":"Date Time", "type": "datetime", "svg":"/icons/svg/calendar-clock.svg", "fieldOptions":false},
    {"label":"Date", "type": "date", "svg":"/icons/svg/calendar-week.svg", "fieldOptions":false},
    {"label":"Time", "type": "time", "svg":"/icons/svg/clock.svg", "fieldOptions":false},
    {"label":"File Upload", "type": "file", "svg":"/icons/svg/folder-arrow-up.svg", "fieldOptions":false},
    {"label":"Image Upload", "type": "image", "svg":"/icons/svg/image.svg", "fieldOptions":false}, 
    // Options Fields
    {"label":"Checkbox", "type": "checkbox", "svg":"/icons/svg/square-check.svg", "fieldOptions":true},
    {"label":"input-radio", "type": "radio", "svg":"/icons/svg/circle-dot.svg", "fieldOptions":true},  
    {"label":"Select", "type": "select", "svg":"/icons/svg/circle-dot.svg", "fieldOptions":true},  
    {"label":"Multi Select", "type": "multi-select", "svg":"/icons/svg/circle-dot.svg", "fieldOptions":true},  
    {"label":"Group Checkbox", "type": "group-checkbox", "svg":"/icons/svg/square-check.svg", "fieldOptions":true},  
    ]